import { TeaserProps } from '@/ts/interfaces';
import getTeaserIndicators from '@/helpers/utils/teaser';
import Footer from '../Footer/Footer';
import Heading from '../Heading/Heading';
import Picture from '../Picture/Picture';
import styles from './WOBTeaser.module.scss';

const WOBTeaser: React.FC<TeaserProps> = ({
  data,
  aspectRatio,
  teaserType,
  index,
  dataTmdatatrack,
  publication,
  placeholderImageUrl,
  as: TeaserHeadline = 'h2',
  scaleImageWidth = 'initial',
  enableAnalytics = false,
  eventName,
  AnalyticData,
}) => {
  const { headline, images, url, primaryTag, platform, source, id, contentType, settings, dates } = data;
  const { showLiveIndicator } = getTeaserIndicators(contentType.type, teaserType, dates?.updated);

  const dataAttributes = {
    'data-tmdatatrack': dataTmdatatrack,
    'data-tmdatatrack-articleid': id,
    'data-tmdatatrack-index': index,
    'data-tmdatatrack-platform': platform,
    'data-tmdatatrack-source': source,
  };

  return (
    <article className={styles[`teaser-${teaserType}`]} {...dataAttributes}>
      <div className={styles['image-box']}>
        <div className={styles.overlay}> </div>
        <Picture
          {...{
            picture: images?.wobTeaser,
            publication,
            aspectRatio,
            scaleImageWidth,
            placeholderImageUrl,
            showLiveIndicator,
          }}
        />
      </div>
      <div className={styles.content}>
        <Heading
          TeaserHeadline={TeaserHeadline}
          url={url}
          headline={headline}
          publication={publication}
          contentType={contentType?.type}
          contentSubType={contentType?.subType}
          teaserType={teaserType}
          eventName={eventName}
          enableAnalytics={enableAnalytics}
          AnalyticData={AnalyticData}
          settings={settings}
        />

        <Footer tagUrl={primaryTag?.tagUrl} tagLabel={primaryTag?.tagName} />
      </div>
    </article>
  );
};

export default WOBTeaser;
