import { useMemo } from 'react';
import AnalyticsTrack from '@/components/atoms/AnalyticsTrack/AnalyticsTrack';
import { publicationFont } from '@/helpers/utils/fonts';
import linkHelper from '@/helpers/analytics/linkHelper';
import { TeaserHeadingProps } from '@/ts';
import { ContentType } from '@/ts/types/contentTypes';
import classNames from '@/helpers/utils/classNames';
import styles from './Heading.module.scss';

const Heading: React.FC<TeaserHeadingProps> = ({
  TeaserHeadline,
  url,
  headline,
  publication,
  contentType,
  teaserType,
  eventName,
  enableAnalytics,
  AnalyticData,
  contentSubType,
  settings,
}) => {
  const affiliateFeature = useMemo(() => {
    const validContentTypes: ContentType[] = ['news', 'opinion', 'photo-story'];
    return validContentTypes.includes(contentType) && contentSubType === 'affiliate'
      ? `${settings?.commercial?.affiliateLabel}:`
      : null;
  }, [contentType, contentSubType, settings]);

  const advertisingFeature = useMemo(
    () =>
      teaserType === 'primary' && contentType === 'news' && contentSubType === 'advertising-feature'
        ? 'AD FEATURE'
        : null,
    [teaserType, contentType, contentSubType]
  );

  const HeadingContent = () => (
    <div className={`${classNames(styles, `heading-${teaserType}`)} ${publication && publicationFont(publication)}`}>
      {advertisingFeature && <span className={styles['advertisement-label']}>{advertisingFeature}</span>}
      {affiliateFeature && <span className={styles['affiliated-label']}>{affiliateFeature}</span>}
      <a className={styles[`teaser-link-${teaserType}`]} href={url} aria-label={headline}>
        <TeaserHeadline className={styles.heading}>{headline}</TeaserHeadline>
      </a>
    </div>
  );

  return enableAnalytics ? (
    <AnalyticsTrack analyticsData={linkHelper(eventName!, url, { ...AnalyticData })}>
      <HeadingContent />
    </AnalyticsTrack>
  ) : (
    <HeadingContent />
  );
};

export default Heading;
