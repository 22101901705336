import { TeaserFooterProps } from '@/ts';
import styles from './Footer.module.scss';

const Footer: React.FC<TeaserFooterProps> = ({ tagLabel, tagUrl }) => (
  <footer className={styles['additional-content']}>
    <a href={tagUrl} aria-label={tagLabel}>
      {tagLabel}
    </a>
  </footer>
);

export default Footer;
