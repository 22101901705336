'use client';

import { TeaserProps } from '@/ts/interfaces';
import WOBTeaser from './WOBTeaser/WOBTeaser';
import StandardTeaser from './StandardTeaser/StandardTeaser';

const Teaser: React.FC<TeaserProps> = (props) => {
  const { teaserType } = props;

  const TeaserComponent = teaserType === 'wob' ? WOBTeaser : StandardTeaser;

  return <TeaserComponent {...props} />;
};

export default Teaser;
