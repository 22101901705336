import publicFolder from '@/helpers/utils/publicFolder';
import classNames from '@/helpers/utils/classNames';
import { TeaserAspectRatio } from '@/ts';
import styles from './Image.module.scss';

type ImagePlaceholderProps = {
  placeholderImageUrl?: string;
  scaleImageWidth?: string;
  aspectRatio?: TeaserAspectRatio;
  publication?: string;
};
const ImagePlaceholder: React.FC<ImagePlaceholderProps> = ({
  aspectRatio,
  scaleImageWidth,
  placeholderImageUrl,
  publication,
}) => {
  const placeholderAspectRatioClass = aspectRatio
    ? `placeholder-aspect-ratio-${aspectRatio.replace(/\s*\/\s*/g, '-')}`
    : `placeholder-aspect-ratio-67-100`;
  const backgroundImageUrl = placeholderImageUrl || `/${publicFolder}/masthead-${publication}-default.svg`;

  return (
    <div
      data-testid="placeholder-image"
      className={classNames(
        styles,
        'placeholder-image',
        placeholderAspectRatioClass,
        scaleImageWidth && `scale-image-${scaleImageWidth}`
      )}
      style={{ backgroundImage: `url('${backgroundImageUrl}')` }}
    />
  );
};
export default ImagePlaceholder;
