import { TeaserType } from '@/ts';
import { ContentType } from '@/ts/types/contentTypes';

const getTeaserIndicators = (
  contentType: ContentType,
  teaserType: TeaserType,
  lastUpdated: string
): { showLiveIndicator: boolean; showVideoIcon: boolean; showPhotoIcon: boolean } => {
  const isLiveTeaser = contentType === 'live-event' && (teaserType === 'primary' || teaserType === 'wob');

  const lastUpdatedDate = new Date(lastUpdated);
  const now = new Date();
  const isWithinThreeHours =
    lastUpdatedDate <= now && (now.getTime() - lastUpdatedDate.getTime()) / (1000 * 60 * 60) <= 3;

  const showLiveIndicator = isWithinThreeHours && isLiveTeaser;

  const isPrimary = teaserType === 'primary';
  const isNewsOrOpinion = contentType === 'news' || contentType === 'opinion';
  const isPhotoStory = contentType === 'photo-story';
  const showVideoIcon = isPrimary && isNewsOrOpinion;
  const showPhotoIcon = isPrimary && isPhotoStory;

  return { showLiveIndicator, showVideoIcon, showPhotoIcon };
};

export default getTeaserIndicators;
